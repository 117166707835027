<template>
  <div >
     <div v-if="showChinese != 'english'">
        <p class="qa_title">1.哪些行为会被系统判定为切屏？</p>
        <p class="qa_content">
          系统弹窗（包含广告、电脑系统提示、社交消息弹窗等）、浏览器退出全屏、最小化浏览器、点击浏览器窗口外的其他地方、使用浏览器页面翻译工具、使用聊天软件，同时开启除谷歌浏览器外的其他浏览器（例如360浏览器等），开启安全卫士等杀毒软件、电脑管家软件等。Mac OS自带的下拉鼠标出现menu bar功能也会被系统判为切屏。
        </p>
        <p class="qa_title" style="margin-top: 12px">2.切屏多少次算作弊？达到切屏上限后会怎么样？</p>
        <p class="qa_content">系统不允许切屏，若检测到切屏行为系统将自动记录，并根据用户的切屏数量、频次、间隔等判断是否存在作弊行为。若被系统判断为作弊行为，系统将会强制自动交卷，无论你是否答完所有问题。</p>

        <p class="qa_title" style="margin-top: 12px">3.页面上为什么不显示鼠标光标？</p>
        <p class="qa_content">为避免选手使用鼠标在页面中使用相关插件取词翻译，当鼠标光标停留在卷面上时将为隐藏状态，将鼠标移动至选项前的圆圈即可选择对应选项。
          鼠标光标在切换题目时（上一题/下一题）可正常显示。</p>

        <p class="qa_title" style="margin-top: 12px">4.题目中提到的材料/图表在哪里查看？</p>
        <p class="qa_content">若题目中提及需要根据材料或图表作答，可在页面考试题目的左侧部分点击“上一页/下一页”翻页查看。</p>

        <p class="qa_title" style="margin-top: 12px">5.考试过程中是否可以去洗手间？</p>
        <p class="qa_content">考试过程中不允许离开摄像头去洗手间，不允许自行关闭摄像头。</p>

        <p class="qa_title" style="margin-top: 12px">6.考试是否可以提前交卷？</p>
        <p class="qa_content">考试结束前15分钟可提前交卷。</p>

        <p class="qa_title" style="margin-top: 12px">7.页面突然空白/黑屏/卡顿，能不能刷新页面？</p>
        <p class="qa_content">可以刷新，选手所在的考试环境中，网络正常流畅的状态下，答题记录将实时保存，刷新前请务必先检查确认网络状态。</p>

        <p class="qa_title" style="margin-top: 12px">8.因误触关闭页面怎么办？答题记录会不会没保存？</p>
        <p class="qa_content">如误触关闭页面，可回到首页后再次进入考试；选手所在的考试环境中，网络正常流畅的状态下，答题记录将实时保存，再次进入考试后请第一时间检查每道题的答题状态。</p>

        <p class="qa_title" style="margin-top: 12px">9.为什么我的页面总题数不足正式考试题数？</p>
        <p class="qa_content">若页面总题数较少，不足正式考试题数，可能为误进入Mock考试，需回到考试系统首页，刷新页面。在【挑战项目】中查看到考试显示为“Formal Test”，即可进入正式考试。</p>

        <p class="qa_title" style="margin-top: 12px">10.系统考试时间倒计时是零怎么办？</p>
        <p class="qa_content">如果没有显示倒计时但是可以正常做题，在保证网络正常的前提下，可尝试刷新页面或直接查看电脑时间自行计时。</p>

    </div>

    <div v-if="showChinese == 'english'">
      <p class="qa_title">1. What actions will be judged by the system as screen cutting?</p>
      <p class="qa_content">
        System pop-ups (including advertisements, computer system prompts, social message pop-ups, etc.), exiting the browser to full screen, minimizing the browser, clicking on other places outside the browser window, using browser page translation tools, using chat software, opening other browsers except Google Chrome, opening anti-virus software such as Security Guard, Computer Manager software, etc. Mac OS's own mouse-down menu bar function will also be judged as screen cut by the system.
      </p>
      <p class="qa_title" style="margin-top: 12px">2. How many screen cuts are considered cheating? </p>
      <p class="qa_content">If screen cutting is detected, the system will automatically record it and determine whether there is cheating based on the number, frequency and interval of screen cutting. If the system determines that there is cheating, the system will force you to turn in the test automatically, regardless of whether you finish answering all the questions.</p>

      <p class="qa_title" style="margin-top: 12px">3. Why is the mouse cursor not displayed on the page?</p>
      <p class="qa_content">In order to avoid players using the mouse to translate words in the page using the relevant plug-ins, the mouse cursor will be hidden when it stays on the page, and the corresponding option can be selected by moving the mouse to the circle in front of the option.
  The mouse cursor can be displayed normally when switching between questions (previous question/next question).</p>

      <p class="qa_title" style="margin-top: 12px">4. Can I go to the bathroom during the test?</p>
      <p class="qa_content">You are allowed to go to the restroom while the camera is on, and you need to return to the camera within 1-2 minutes.
  </p>

      <p class="qa_title" style="margin-top: 12px">5. Is it possible to turn in the exam early?</p>
      <p class="qa_content">You can turn in your exam early.</p>

      <p class="qa_title" style="margin-top: 12px">6. Can the page be refreshed if the page is suddenly blank/black/stalled?</p>
      <p class="qa_content">You can refresh the page. Please check the network status before refreshing.</p>

      <p class="qa_title" style="margin-top: 12px">7. What if I close the page by mistake? Will the answer records be saved?</p>
      <p class="qa_content">If you close the page by mistake, you can go back to the home page and enter the test again; in the test environment where the player is located, the answer record will be saved in real time when the network is normal and smooth, please check the answer status of each question after entering the test again.</p>


    </div>
  </div>

  


</template>

<script>

import { getProjectLanguage } from "../../utils/store";


export default {
  name: "QA",
  data() {

    return {
      showChinese:getProjectLanguage()
    }
  },

}

</script>

<style scoped>
.qa_title {
  color: #484949;
  font-size: 16px;
  font-weight: bold;
}

.qa_content {
  margin-top: 10px;
  color: #707170;
  font-size: 14px;
  line-height: 22px;
}

</style>