<template>
  <div>
    <div @click="init" class="tag">
      {{
        status
          ? $t('m.jiankaozhong')
          : $t('m.chonglian')
      }}
    </div>
    <!-- 存放canvas的容器 实际页面不显示 -->
    <canvas
      id="cameraCanvas"
      :width="cameraOptions.video.width"
      :height="cameraOptions.video.height"
      style="display: none"
    ></canvas>
    <canvas
      id="screenCanvas"
      :width="screenOptions.video.width"
      :height="screenOptions.video.height"
      style="display: none"
    ></canvas>
  </div>
</template>

<script>
import { getUserId, getProjectCode } from "../../../utils/store";
import {
  uploadExamShotNew,
  changeVisibleLog,
  initExamShotTimePoint,
} from "../../../api/index";
export default {
  props: {
    cameraOptions: {
      default() {
        return {
          audio: false,
          video: { width: 500, height: 500 },
        };
      },
    },
    screenOptions: {
      default() {
        return {
          audio: false,
          video: { width: 1280, height: 720 },
        };
      },
    },
    value: [Boolean],
    shotInterval: {
      default: 10000,
      type: Number,
    },
    template_id: [String],
  },
  data() {
    return {
      cameraVideo: null, // 摄像头视频容器
      screenVideo: null, // 屏幕视频容器
      cameraStream: null, // 摄像头视频流
      screenStream: null, // 屏幕视频流
      status: this.value,
      timer: "",
      visibleShotTimer: "",
      shotTable: [],
      index: 0,
    };
  },
  created() {
    this.startCheckOnline(); //检查在线状态
    if (!this.status) {
      this.init();
    }
  },
  destroyed() {
    window.onblur = null;
    window.onfocus = null;
    this.clearCheckOnline();
    this.clearAllResource();
    this.clearScreenVisibleShot();
  },
  methods: {
    onblur() {
      changeVisibleLog(getUserId(), getProjectCode(), this.template_id).then(
        (res) => {
          this.$emit("visibleChange");
        }
      );
      this.setScreenVisibleShot();
    },
    onfocus() {
      this.clearScreenVisibleShot();
    },
    // 先执行这个方法 同时获取摄像头与屏幕流权限 获取流
    async init() {
      if (this.status) {
        return;
      }
      this.clearScreenVisibleShot();
      this.clearAllResource();
      // 判断浏览器是否有权限
      if (
        !navigator ||
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getUserMedia
      ) {
        this.$message.error("浏览器不支持访问摄像头!");
        return;
      }
      if (
        !navigator ||
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getDisplayMedia
      ) {
        this.$message.error("浏览器不支持屏幕录制!");
        return;
      }

      this.cameraVideo = document.createElement("video");
      this.screenVideo = document.createElement("video");

      // 视频流

      const loading = this.$loading({
        lock: true,
        text: "获取摄像头权限中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let stream = await navigator.mediaDevices
        .getUserMedia(this.cameraOptions)
        .catch((err) => {
          //摄像头接口常见错误
          //AbortError［中止错误］尽管用户和操作系统都授予了访问设备硬件的权利，而且未出现可能抛出NotReadableError异常的硬件问题，但仍然有一些问题的出现导致了设备无法被使用。
          //NotAllowedError［拒绝错误］(主要是ios权限没开)用户拒绝了当前的浏览器实例的访问请求；或者用户拒绝了当前会话的访问；或者用户在全局范围内拒绝了所有媒体访问请求。
          //NotFoundError［找不到错误］找不到满足请求参数的媒体类型。
          //NotReadableError［无法读取错误］尽管用户已经授权使用相应的设备，操作系统上某个硬件、浏览器或者网页层面发生的错误导致设备无法被访问。
          //OverconstrainedError［无法满足要求错误］指定的要求无法被设备满足，此异常是一个类型为OverconstrainedError的对象，拥有一个constraint属性，这个属性包含了当前无法被满足的constraint对象，还拥有一个message属性，包含了阅读友好的字符串用来说明情况。
          //SecurityError［安全错误］在getUserMedia() 被调用的 Document 上面，使用设备媒体被禁止。这个机制是否开启或者关闭取决于单个用户的偏好设置。
          //TypeError［类型错误］constraints对象未设置［空］，或者都被设置为false。可能代码配置错了
          this.$message.error(`摄像头获取失败 error:${err}`);
          this.clearAllResource();
          this.clearScreenVisibleShot();
          return;
        })
        .finally(() => {
          loading.close();
        });
      this.cameraStream = stream;
      this.cameraVideo.srcObject = stream;
      this.cameraVideo.play();
      loading.close();

      // 屏幕流

      const loading1 = this.$loading({
        lock: true,
        text: "获取屏幕分享中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let stream1 = await navigator.mediaDevices
        .getDisplayMedia(this.screenOptions)
        .catch((err) => {
          //屏幕分享接口常见错误
          //AbortError ［中止错误］发生了与以下任何其他异常不匹配的错误或故障。
          //InvalidStateError ［拒绝错误］调用 getDisplayMedia() 的context中的 document 不是完全激活的; 例如，也许它不是最前面的标签。
          //NotAllowedError ［拒绝错误］（一般IOS权限没开）用户拒绝授予访问屏幕区域的权限，或者不允许当前浏览实例访问屏幕共享。
          //NotFoundError ［找不到错误］没有可用于捕获的屏幕视频源。
          //NotReadableError ［无法读取错误］用户选择了屏幕，窗口，标签或其他屏幕数据源，但发生了硬件或操作系统级别错误或锁定，从而预先占用了共享所选源。
          //OverconstrainedError ［转换错误］创建流后，由于无法生成兼容的流导致应用指定的 constraints 失效。
          //TypeError ［类型错误］指定的 constraints 包括调用 getDisplayMedia() 时不允许的constraints。 这些不受支持的constraints是 advanced 的，任何约束又有一个名为 min 或 exact 的成员。
          this.$message.error(`屏幕获取失败 error:${err}`);
          this.clearAllResource();
          this.clearScreenVisibleShot();
          return;
        })
        .finally(() => {
          loading1.close();
        });

      if (
        stream1 &&
        stream1.getVideoTracks()[0].getSettings().displaySurface !== "monitor"
      ) {
        this.$alert("请选择分享整个屏幕", "屏幕共享失败", {
          confirmButtonText: "确定",
          type: "error",
        });
        this.clearAllResource();
        this.clearScreenVisibleShot();
        return;
      }
      this.screenStream = stream1;
      this.screenVideo.srcObject = stream1;
      this.screenVideo.play();
      this.status = true;
      this.$emit("input", true);
      this.startShot();
      loading.close();

      window.onblur = this.onblur;
      window.onfocus = this.onfocus;
    },
    getShot(isChange) {
      // camera or screen
      if (this.status) {
        let camera_pic = "";
        let screen_pic = "";
        if (this.cameraVideo) {
          let canvas = document.querySelector("#cameraCanvas");
          let ctx = canvas.getContext("2d");
          ctx.drawImage(
            this.cameraVideo,
            0,
            0,
            this.cameraOptions.video.width,
            this.cameraOptions.video.height
          );
          // 截屏的base64图片
          camera_pic = canvas.toDataURL();
        }
        if (this.screenVideo) {
          let canvas = document.querySelector("#screenCanvas");
          let ctx = canvas.getContext("2d");
          ctx.drawImage(
            this.screenVideo,
            0,
            0,
            this.screenOptions.video.width,
            this.screenOptions.video.height
          );
          // 截屏的base64图片
          screen_pic = canvas.toDataURL();
        }
        let camera_file = "";
        let screen_file = "";
        if (camera_pic) {
          camera_file = this.base64ToFile(camera_pic, "camera_pic");
        }
        if (screen_pic) {
          screen_file = this.base64ToFile(screen_pic, "screen_pic");
        }
        let param = new FormData();
        param.append("function", "uploadExamShotNew");
        param.append("student_id", getUserId());
        param.append("project_code", getProjectCode());
        param.append("template_id", this.template_id);
        param.append("camera_file", camera_file);
        param.append("screen_file", screen_file);
        if (isChange) {
          param.append("isChange", true);
        }
        uploadExamShotNew(param).then((res) => {
          console.log(res);
        });
      }
    },
    clearAllResource() {
      if (this.cameraStream && this.cameraStream.getTracks) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
      }
      if (this.screenStream && this.screenStream.getTracks) {
        this.screenStream.getTracks().forEach((track) => track.stop());
      }
      this.cameraVideo = null;
      this.screenVideo = null;
      this.cameraStream = null;
      this.screenStream = null;
      this.status = false;
      this.$emit("input", false);
    },
    startCheckOnline() {
      this.timer = setInterval(() => {
        if (
          !this.cameraStream ||
          !this.cameraStream.active ||
          !this.screenStream ||
          !this.screenStream.active
        ) {
          if (this.status) {
            this.clearAllResource();
            this.clearScreenVisibleShot();
          }
          this.status = false;
          this.$emit("input", false);
        }
      }, 1000);
    },
    startShot() {
      initExamShotTimePoint(
        getUserId(),
        getProjectCode(),
        this.template_id
      ).then((res) => {
        this.index = 0;
        this.shotTable = this.changeShotTable(res.data);
        this.shotByTable();
      });
    },
    shotByTable() {
      setTimeout(() => {
        if (!document.hidden) {
          this.getShot();
        }
        this.index++;
        if (this.index <= this.shotTable.length - 1 && this.status) {
          this.shotByTable();
        }
      }, this.shotTable[this.index] * 1000);
    },
    setScreenVisibleShot() {
      this.visibleShotTimer = setInterval(() => {
        this.getShot(true);
      }, 5000);
    },
    clearScreenVisibleShot() {
      clearInterval(this.visibleShotTimer);
    },
    clearCheckOnline() {
      clearInterval(this.timer);
    },
    changeShotTable(table) {
      let newTable = [];
      for (let i in table) {
        if (i !== 0) {
          let newData = table[i] - table[i - 1];
          newTable.push(newData);
        }
      }
      return newTable;
    },
    base64ToFile(base64, name) {
      if (base64) {
        let dataurl = base64;
        let arr = dataurl.split(",");
        let mine = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let obj = new File([u8arr], name, { type: mine });
        return obj;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.tag {
  cursor: pointer;
  padding: 2px 10px;
  color: white;
  border: 2px solid white;
}
</style>
